import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles, DialogTitle as MuiDialogTitle, Typography, IconButton, makeStyles, useTheme, useMediaQuery, Dialog, Box, Grid, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Button, SvgIcon, DialogContent, TableCell, TableBody, TableRow, TableContainer, Table, TableHead } from '@material-ui/core'
import moment from 'moment'
import { Close as CloseIcon } from '@material-ui/icons'
import { FileText as FileIcon, ChevronLeft as LeftIcon, ChevronRight as RightIcon } from 'react-feather'
import { europeNum, getDeviceLogs, getExcelLogs } from '@/utils/general'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useSnackbar } from 'notistack'
import ModalExcelDeviceLogs from './ModalExcelDeviceLogs'
import fileDownload from 'js-file-download'

// DialogTitle personalizzato
const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const getAssetPropertiesLabelMapping = (plantType = 'hydro') => {
  const mapping = new Map()
  if (plantType === 'publicEnergy') {
    mapping.set('power', 'Potenza')
    mapping.set('impenergy', 'Energia')
  } else {
    mapping.set('quotalibera', 'Quota s.l.m pelo libero')
    mapping.set('quotaslm', 'Quota s.l.m')
    mapping.set('sensor', 'Perc. Sensore')
    mapping.set('distmisurata', 'Dist. misurata dallo strumento')
  }

  return mapping
}

const DialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h4'>{children}</Typography>
      {onClose
        ? (
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          )
        : null}
    </MuiDialogTitle>
  )
})

const logsParams = {
  deviceId: '',
  name: [],
  timeTo: null,
  timeFrom: null
}

// Stili del componente
const useStyles = makeStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%'
  },
  // tableContainer: {
  //  maxHeight: '100%',
  // },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  selectLabel: {
    backgroundColor: theme.palette.background.paper,
    paddingRight: 8,
    paddingLeft: 8
  }
}))

// funzione che torna l'array con le colonne
const generateLogColumns = (deviceProps, assetPropertyMappings, plantType) => {
  const assetLabelPropertyMappings = getAssetPropertiesLabelMapping(plantType)
  const assetProps = [...assetLabelPropertyMappings?.keys()]

  const arrayToSend = []
  if (assetProps) {
    assetProps.forEach(assetKey => {
      const deviceKeys = Object.keys(deviceProps)
      const deviceProp = deviceKeys.find(key => key.includes(assetPropertyMappings[assetKey].propertyName)) ?? ''
      const uom = deviceProps[deviceProp]?.uom && deviceProps[deviceProp]?.uom !== '-' ? deviceProps[deviceProp]?.uom : null

      const propObject = {
        prop: deviceProp,
        label: uom ? `${assetLabelPropertyMappings.get(assetKey)} (${uom})` : `${assetLabelPropertyMappings.get(assetKey)}`
      }

      arrayToSend.push(propObject)
    })
  }

  return arrayToSend
}

// funzione che ritorna l'array delle righe
const generateLogRows = (data) => {
  const arrayToReturn = []
  // mi scorro i valori di tutte le prop e mi salvo i valori ISOString che non ho
  Object.keys(data).forEach((key) => {
    data[key].forEach((elem) => {
      if (arrayToReturn.indexOf(elem[0]) === -1) {
        arrayToReturn.push(elem[0])
      }
    })
  })
  // Ordino l'array in base al tempo (dal più vicino al from fino al to)
  arrayToReturn.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
  return arrayToReturn
}

// Funzione che preso in ingresso l'oggetto dei logs di un singolo device restituisce lo stesso oggetto ma trascurando la diversità di secondi tra un messaggio e l'altro
const approximateLogsToMinutesPrecision = (logsObj) => {
  if (logsObj) {
    return Object.keys(logsObj).reduce((acc, propKey) => {
      const newProp = logsObj[propKey]
        .map(dateValueEl => dateValueEl?.map((el, index) => index === 0 ? moment(el).format('DD/MM/YYYY HH:mm') : el))

      acc[propKey] = newProp

      return acc
    }, {})
  }

  return {}
}

// funzione che torna l'height di un nodo
const getElemHeight = (elemId, decrease) => {
  const elem = document.getElementById(elemId)
  if (elem) {
    return decrease ? elem.clientHeight - decrease : elem.clientHeight
  } else {
    return 'auto'
  }
}

const addAnHour = (date) => {
  if (date) {
    return moment(date)
      .add({ hours: '1' })
      .toISOString()
  } else {
    return null
  }
}

const maxWidth = 'lg'

const ModalAssetsLogs = ({
  open,
  onClose,
  selectedAsset,
  toggleDownloadExcel,
  devices,
  plantType,
  onLoad
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [tableHeight, setTableHeight] = useState(null)
  // mi preparo le date
  const [selectedDay, setSelectedDay] = useState(moment().set({ minute: 0, second: 0, millisecond: 0 }).toISOString())
  // variabile con l'array delle colonne da visualizzare
  const [selectedColumns, setSelectedColumns] = useState(['all'])

  // Variabile con l'array delle colonne
  const [columns, setColumns] = useState([])

  // Variabile con le righe
  const [rows, setRows] = useState([])

  // Variable che contiene i dati
  const [data, setData] = useState({})

  // Variabile che mostra/nasconde la modal dello scaricamento logs in excel
  const [showExcelLogs, setShowExcelLogs] = useState(false)

  // Variabile che controlla le dimensioni dell'excel
  const [isExcelTooBig, setIsExcelTooBig] = useState(false)

  // variabile che mi specifica se ho dati altrimenti nasconde tabella
  // di default è true perchè poi mi scorro i dati e se ne trovo 1 metto a false
  let dataIsEmpty = true
  // controllo se ho i dati
  if (Object.keys(data).length > 0) {
    Object.keys(data).forEach((key) => {
      if (data[key].length > 0) {
        dataIsEmpty = false
      }
    })
  }

  const returnCellValue = (valuesArray, ref, prop) => {
    if (valuesArray) {
      const elem = valuesArray.find((el) => el[0] === ref)
      if (elem) {
        if (prop === 'status') {
          return elem[1]
        } else {
          return europeNum(elem[1], 2)
        }
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  const updatedMaxHeight = () => {
    // console.log('dentro update height')
    setTableHeight(() => getElemHeight('logsTableContainer'))
  }

  // funzione che cambia l'ora al calendario
  const handleChangeHour = () => {
    setTableHeight(null)
    setTimeout(() => {
      updatedMaxHeight()
    }, 200)
  }

  // funzione che manda avanti o indietro di un'ora il calendario
  const handleQuickPeriodChange = (action) => {
    let dateToSend = moment(selectedDay)
    if (action === 'prev') {
      dateToSend = moment(dateToSend).subtract({ hours: '1' })
    } else if (action === 'next') {
      dateToSend = moment(dateToSend).add({ hours: '1' })
    }
    handleChangeHour()
    setSelectedDay(dateToSend)
  }

  const handleClose = () => {
    setSelectedColumns(['all'])
    setTableHeight(null)
    onClose()
  }

  // lancio l'onLoad
  if (onLoad) {
    onLoad()
  }

  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const propertyName = plantType === 'publicEnergy' ? 'power' : 'adc'

  const getLogsData = useCallback(async (asset, selectedDate) => {
    const deviceId = asset.propertyMappings?.[propertyName]?.resourceId ?? ''

    try {
      const from = moment(selectedDate)
      const to = addAnHour(selectedDate)
      // setto i valori nei parametri della query
      logsParams.deviceId = deviceId
      // mi calcolo le giuste propsLabels
      const currentDevice = devices.find(device => device.uuid === deviceId)
      const newColumns = generateLogColumns(currentDevice.deviceType?.properties, selectedAsset.propertyMappings, plantType)

      logsParams.name = newColumns.map((col) => col.prop)
      logsParams.timeFrom = from
      logsParams.timeTo = to
      const newData = await getDeviceLogs(logsParams)

      // mi preparo le righe
      const formattedData = approximateLogsToMinutesPrecision(newData[currentDevice?.uuid] || {})
      const newRows = generateLogRows(formattedData)

      setData(formattedData)
      setRows(newRows)
      setColumns(newColumns)
    } catch (error) {
      console.error('openLogsDialog, error => ', error)
      enqueueSnackbar(`Errore nel reperire i dati del sensore di livello ${asset.name || ''}. Per favore riprovare.`, {
        variant: 'error'
      })
    }
  }, [])

  // funzione che scarica l'excel
  const downloadExcel = async (from, to, selectedAggregation) => {
    setIsExcelTooBig(false)
    const excelParams = JSON.parse(JSON.stringify(logsParams))
    excelParams.timeFrom = moment(from).toISOString()
    excelParams.timeTo = moment(to).toISOString()
    excelParams.aggregationType = selectedAggregation || 'quarter'
    try {
      const excelResponse = await getExcelLogs(excelParams)
      // controllo se è ritornato il file o se manda email
      if (excelResponse.status === 202) {
        // mando l'excel per email
        setIsExcelTooBig(true)
      } else {
        // scarico il blob
        fileDownload(
          excelResponse.data,
          `LOGS ${selectedAsset.name} - dal ${moment(from).format('DD-MM-YY (HH)')} al ${moment(to).format(
            'DD-MM-YY (HH)'
          )} - dati tipo ${selectedAggregation || 'quarter'}.xlsx`
        )
      }
    } catch (err) {
      console.error('get excel err => ', err)
      if (String(err).indexOf('404') > -1) {
        enqueueSnackbar('Attenzione: non ci sono dati per il tipo di aggregazione richiesta.', {
          variant: 'warning'
        })
      } else {
        enqueueSnackbar('Errore nel richiedere i log in Excel, per favore aggiornare la pagina e riprovare.', {
          variant: 'error'
        })
      }
    }
  }

  useEffect(() => {
    getLogsData(selectedAsset, selectedDay)
  }, [selectedAsset, selectedDay])

  return (
    <>
      {showExcelLogs
        ? <ModalExcelDeviceLogs
            open={showExcelLogs}
            onClose={() => setShowExcelLogs(false)}
            deviceId={propertyName ?? ''}
            name={selectedAsset.name ?? 'Sensore di livello'}
            initialFrom={moment(selectedDay)}
            initialTo={addAnHour(selectedDay)}
            downloadExcel={downloadExcel}
            isExcelTooBig={isExcelTooBig}
          />
        : null}
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        fullWidth
        TransitionProps={{
          onEntered: updatedMaxHeight
        }}
      >
        <DialogTitle disableTypography id='log-dialog-title' onClose={handleClose}>
          Log {selectedAsset.name ?? 'Sensore di livello'}
        </DialogTitle>
        <Box pl={2} pr={2}>
          <Typography component='p' variant={!isExtraSmall ? 'body1' : 'body2'} style={{ marginBottom: 16 }}>
            Seleziona la data e l'ora per cui visualizzare i dati.
          </Typography>
          <Grid container alignItems='center' spacing={2}>
            <Grid item xs={2} sm='auto'>
              <IconButton aria-label='close' onClick={() => handleQuickPeriodChange('prev')}>
                <LeftIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8} sm='auto'>
              <KeyboardDateTimePicker
                className={classes.datePicker}
                label='Data ed ora'
                size='small'
                format='DD/MM/YYYY HH:mm'
                name='selectedDay'
                disableFuture
                // maxDate={excelTo}
                // variant='inline'
                views={['date', 'hours']}
                ampm={false}
                inputVariant='outlined'
                value={selectedDay}
                onChange={(date) => {
                  // let dateToSend = moment(date).set({ minute: 0, second: 0, millisecond: 0 })
                  const dateToSend = moment(date).set({ minute: 0, second: 0, millisecond: 0 }).toISOString()
                  handleChangeHour()
                  setSelectedDay(dateToSend)
                }}
              />
            </Grid>
            <Grid item xs={2} sm='auto'>
              <IconButton aria-label='close' onClick={() => handleQuickPeriodChange('next')}>
                <RightIcon />
              </IconButton>
            </Grid>
            <Grid item xs>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel id='selectedColumns-label' className={classes.selectLabel}>
                  Grandezze visualizzate
                </InputLabel>
                <Select
                  labelId='selectedColumns-label'
                  id='selectedColumns'
                  // label='Grandezze da visualizzare'
                  multiple
                  // variant='outlined'
                  disabled={dataIsEmpty}
                  value={selectedColumns}
                  // input={<Input />}
                  renderValue={(selected) => {
                    const arrayToSend = selected.map((selection) => {
                      if (selection === 'all') {
                        return 'Tutte'
                      } else {
                        const thisCol = columns.find((column) => column.prop === selection)
                        return thisCol.label
                      }
                    })
                    return arrayToSend.join(', ')
                  }}
                  onChange={(event) => {
                    const newSelection = [...event.target.value]
                    // se non ha selezionato niente lascio all
                    if (newSelection.length === 0) {
                      setSelectedColumns(['all'])
                    } else {
                      // controllo se ci sono stati cambiamenti, in particolare per 'all'
                      const allIndexPrev = selectedColumns.indexOf('all')
                      const allIndexNew = newSelection.indexOf('all')
                      if (allIndexNew === -1) {
                        // non ho all nel nuovo, significa che non c'era prima e non è stato selezionato, pusho tutto così com'è
                        setSelectedColumns(newSelection)
                      } else {
                        // nel nuovo c'è all
                        if (allIndexPrev === -1) {
                          // non c'era all prima, quindi ha selezionato all quando prima era deselezionato, pusho solo lui
                          setSelectedColumns(['all'])
                        } else {
                          // all c'era prima ora pure ma ha selezionato anche altro quindi rimuovo all e pusho
                          newSelection.splice(allIndexNew, 1)
                          setSelectedColumns(newSelection)
                        }
                      }
                    }
                  }}
                >
                  <MenuItem value='all'>
                    <Checkbox checked={selectedColumns.indexOf('all') > -1} color='primary' />
                    <ListItemText primary='Tutte' />
                  </MenuItem>
                  {columns.map((column, index) => (
                    <MenuItem key={index} value={column.prop}>
                      <Checkbox checked={selectedColumns.indexOf(column.prop) > -1} color='primary' />
                      <ListItemText primary={column.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!isExtraSmall && (
              <Grid item>
                <Button
                  color='primary'
                  variant='contained'
                  startIcon={
                    <SvgIcon fontSize='small'>
                      <FileIcon />
                    </SvgIcon>
                  }
                  onClick={() => setShowExcelLogs(true)}
                  style={{ marginLeft: 12 }}
                >
                  Excel
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        <DialogContent id='logsTableContainer' style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, overflow: 'hidden' }}>
          <PerfectScrollbar>
            <TableContainer style={{ maxHeight: tableHeight }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Data</TableCell>
                    {columns
                      .filter((column) => {
                        if (selectedColumns.indexOf('all') > -1) {
                          return true
                        } else {
                          return selectedColumns.indexOf(column.prop) > -1
                        }
                      })
                      .map((column, index) => {
                        let finalIndex = index
                        if (selectedColumns.indexOf('all') === -1) {
                          finalIndex = columns.findIndex((col) => col.prop === column.prop)
                        }
                        return (
                          <TableCell key={index} align='center'>
                            {columns[finalIndex].label || column.label}
                          </TableCell>
                        )
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataIsEmpty
                    ? (
                      <TableRow>
                        <TableCell align='left' colSpan={columns.length}>
                          Non ci sono dati da visualizzare per il periodo selezionato.
                        </TableCell>
                      </TableRow>
                      )
                    : (
                        rows.map((row, rowIndex) => {
                          return (
                            <TableRow key={rowIndex}>
                              <TableCell align='left'>{typeof row === 'string' ? row : moment(row).format('DD/MM/YYYY HH:mm')}</TableCell>
                              {columns
                                .filter((column) => {
                                  if (selectedColumns.indexOf('all') > -1) {
                                    return true
                                  } else {
                                    return selectedColumns.indexOf(column.prop) > -1
                                  }
                                })
                                .map((column, columnIndex) => {
                                  return (
                                    <TableCell key={columnIndex} align='center'>
                                      {returnCellValue(data[column.prop], row, column.prop)}
                                    </TableCell>
                                  )
                                })}
                            </TableRow>
                          )
                        })
                      )}
                </TableBody>
              </Table>
            </TableContainer>
          </PerfectScrollbar>
        </DialogContent>
      </Dialog>
    </>
  )
}

ModalAssetsLogs.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  toggleDownloadExcel: PropTypes.func,
  onLoad: PropTypes.func
}

export default ModalAssetsLogs
